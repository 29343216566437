<template>
  <div class="teacher-page">
    <div class="content-wrap">
      <div class="teacher-info">
        <div class="left">
          <img :src="teacherInfo.front_cover" />
        </div>
        <div class="right">
          <div class="name">{{ teacherInfo.name }}</div>
<!--          <div class="tag">-->
<!--            <span>标签展示</span>-->
<!--            <span>标签内容展示</span>-->
<!--          </div>-->
          <div class="info">{{ teacherInfo.introduction }}</div>
        </div>
      </div>
      <div class="teacher-course">
        <div class="title">
          <div></div>
          <span>推荐课程</span>
        </div>
        <template v-if="teacherInfo && teacherInfo.course && teacherInfo.course.data.length > 0">
          <div class="course-wrap">
            <div class="course-item" v-for="item in teacherInfo.course.data" :key="item.id" @click="toNavInfo(item)">
              <div class="cover">
                <img :src="item.front_cover2" />
              </div>
              <div class="name">{{ item.name }}</div>
              <div class="info">
                <span class="count">{{ item.pay_num }}人已报名</span>
                <span v-if="item.is_free === 1">免费</span>
                <span v-else class="price">￥{{ item.price }}</span>
              </div>
            </div>
          </div>
          <div v-if="teacherInfo && teacherInfo.course && teacherInfo.course.data.length < total" class="page-wrap">
            <el-pagination
              background
              layout="prev, pager, next"
              :page-size="queryParam.size"
              :current-page="queryParam.page"
              :total="total"
              @current-change="handleCurrentChange"
            />
            <div class="total-wrap">共 {{ total }} 条</div>
          </div>
        </template>
        <empty-data v-else />
      </div>
    </div>
  </div>
</template>

<script>
import * as courseService from '@/service/course-service';
import emptyData from '@/views/modules/empty-data';
export default {
  name: 'teacherDetail',
  components: {
    emptyData
  },
  data() {
    return {
      teacherInfo: {},
      queryParam: {
        size: 12,
        page: 1
      },
      total: 0
    };
  },
  created() {
    this.getTeacherInfo();
  },
  methods: {
    getTeacherInfo() {
      const data = {
        teacher_id: this.$route.query.id,
        ...this.queryParam
      };
      courseService.getCourseTeacherInfo(data).then(res => {
        if (res.code === 1) {
          this.teacherInfo = res.data;
          this.total = res.data.course.total;
        }
      });
    },
    /**
     * 跳转详情
     * course_type 1=普通课程 2=直播课 0=套课
     * */
    toNavInfo(info) {
      const path = info.course_type === '1' ? '/courseDetail' : info.course_type === '2' ? 'liveDetail' : '/packageDetail';
      this.$router.push({
        path: path,
        query: {
          id: info.id
        }
      });
    },
    handleCurrentChange(val) {
      this.queryParam.page = val;
      this.getTeacherInfo();
    }
  }
};
</script>

<style lang="scss" scoped>
.teacher-page {
  line-height: 1;
  .content-wrap {
    display: flex;
    flex-direction: column;
    padding: 20px 0 50px;
    .teacher-info {
      display: flex;
      background: #FFFFFF;
      border-radius: 8px;
      padding: 20px 36px 20px 25px;
      margin-bottom: 20px;
      .left {
        margin-right: 25px;
        img {
          width: 440px;
          border-radius: 8px;
        }
      }
      .right {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        flex: 1;
        .name {
          margin-top: 16px;
          font-size: 24px;
          color: #222222;
          margin-bottom: 20px;
        }
        .tag {
          display: flex;
          align-items: center;
          font-size: 16px;
          font-weight: 400;
          color: #2869F5;
          margin-bottom: 24px;
          span {
            padding-right: 12px;
            border-right: 1px solid #2869F5;
            margin-right: 12px;
            border-radius: 1px;
          }
          span:last-child {
            border-right: none;
          }
        }
        .info {
          font-size: 16px;
          font-weight: 400;
          color: #222222;
          line-height: 26px;
        }
      }
    }
    .teacher-course {
      display: flex;
      flex-direction: column;
      background: #FFFFFF;
      border-radius: 8px;
      padding: 24px 30px 20px;
      .title {
        display: flex;
        align-items: center;
        margin-bottom: 25px;
        div {
          width: 4px;
          height: 21px;
          background: #2869F5;
          border-radius: 1px;
          margin-right: 12px;
        }
        span {
          font-size: 20px;
          color: #333333;
        }
      }
      .course-wrap {
        display: flex;
        flex-wrap: wrap;
        .course-item {
          display: flex;
          flex-direction: column;
          width: 360px;
          height: 310px;
          background: #F8F9FB;
          border-radius: 8px;
          margin-bottom: 20px;
          margin-right: 30px;
          overflow: hidden;
          cursor: pointer;
          transition: all .4s;
          .cover {
            margin-bottom: 17px;
            img {
              width: 360px;
              height: 222px;
              border-radius: 8px 8px 0px 0px;
            }
          }
          .name {
            font-size: 18px;
            font-weight: 500;
            color: #333333;
            margin-bottom: 15px;
            padding: 0 19px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .info {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 18px 0 19px;
            span {
              font-size: 18px;
              color: #E22323;
            }
            .count {
              font-size: 12px;
              font-weight: 400;
              color: #666666;
            }
            .price {
              font-size: 18px;
              font-weight: bold;
              color: #E22323;
            }
          }
        }
        .course-item:nth-child(3n) {
          margin-right: 0;
        }
        .course-item:hover {
          box-shadow: 2px 4px 9px 0px rgba(40,105,245,0.09);
          transform: translateY(-5px);
        }
      }
    }
    .page-wrap {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 48px;
      ::v-deep .el-pagination.is-background .btn-next, ::v-deep .el-pagination.is-background .btn-prev, ::v-deep .el-pagination.is-background .el-pager li {
        background-color: #FFFFFF;
      }
      ::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
        background: #2869F5;
      }
      .total-wrap {
        margin-left: 16px;
        font-size: 14px;
        font-weight: 400;
        color: #333333;
      }
    }
  }
}
</style>
